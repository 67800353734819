.DayPicker {
  border: 1px;
  border-color: rgba(0, 0, 0, 0.21);
  border-radius: 4px;
  border-style: outset;
}

.DayPicker-wrapper {
  outline-color: #dc9234;
}

.DayPicker-Day {
  outline: none;
  border-radius: 2px;
}

.DayPicker-Day--today {
  color: #2f3787;
}

.DayPicker-Day--selected {
  color: white;
  background-color: #dc9234 !important;
  border-radius: 2px;
  outline: none;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #dc93342f;
}