.rbc-event {
  background-color: #2f3787;
}

.rbc-off-range-bg {
  background-color: #2f378727;
}

.rbc-today {
  background-color: #dc923427;
}

.rbc-current-time-indicator {
  background-color: #dc9234;
  height: 2px;
}